import currentUser from "../../store/modules/current-user";

export function getTimeObject() {
  function getOffsetTime() {
    return new Date(new Date().getTime() - currentUser.state.clockOffset);
  }

  function offsetToISOString() {
    return getOffsetTime().toISOString();
  }

  function offsetGetTime() {
    return getOffsetTime().getTime();
  }

  function offsetToLocaleString() {
    return getOffsetTime().toLocaleString();
  }

  function offsetGetHours() {
    return getOffsetTime().getHours();
  }

  function offsetToString() {
    return getOffsetTime().toString();
  }

  return { getOffsetTime, offsetToISOString, offsetGetTime, offsetToLocaleString, offsetGetHours, offsetToString };
}
