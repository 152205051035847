import { getTimeObject } from "@/utils/time/getTimeObject";
import currentUser from "./current-user";
import { DateTime } from "luxon";

export default {
  namespaced: true,
  state: {
    timeSeriesType: "",
    agentStatusFilter: "",
    startDate: getTimeObject().getOffsetTime(),
    endDate: getTimeObject().getOffsetTime(),
    selectedFilter: {
      label: "Today",
      value: "today",
    },
    isPresent: true,
    agentPerformanceTable: [],
    queuePerformanceTable: [],
    agentPerformanceOfflineFilter: false,
    selectedDropdownIndex: 0,
  },
  mutations: {
    setTimeSeriesType(state, value) {
      state.timeSeriesType = value;
    },
    setAgentStatusFilter(state, value) {
      state.agentStatusFilter = value;
    },
    setAgentPerformanceOfflineFilter(state, value) {
      state.agentPerformanceOfflineFilter = value;
    },
    setStartDate(state, value) {
      state.startDate = value;
    },
    setEndDate(state, value) {
      state.endDate = value;
    },
    setSelectedFilter(state, value) {
      state.selectedFilter = value;
    },
    setIsPresent(state, value) {
      state.isPresent = value;
    },
    setAgentPerformanceTable(state, value) {
      state.agentPerformanceTable = value;
    },
    setQueuePerformanceTable(state, value) {
      state.queuePerformanceTable = value;
    },
    setDropdownIndex(state, value) {
      state.selectedDropdownIndex = value;
    },
  },
  getters: {
    dateRange(state) {
      const timezone = currentUser.state.instanceTimezone.value;

      let start = DateTime.fromJSDate(state.startDate, { zone: timezone });
      let end = DateTime.fromJSDate(state.endDate, { zone: timezone });
      if (end.day !== start.day) {
        start = start.set({ day: end.day });
      }

      return [start.toISO(), end.toISO()];
    },
    getAgentPerformanceOfflineFilter(state) {
      return state.agentPerformanceOfflineFilter;
    },
    getTimeSeriesType(state) {
      return state.timeSeriesType;
    },
    getSelectedDropdownIndex(state) {
      return state.selectedDropdownIndex;
    },
  },
  actions: {
    updateAgentPerformanceOfflineFilter({ commit }, payload) {
      commit("setAgentPerformanceOfflineFilter", payload);
    },
    updateTimeSeriesType({ commit }, payload) {
      commit("setTimeSeriesType", payload);
    },
  },
};
