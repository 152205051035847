import { Ability } from "@casl/ability";
import { ABILITY_ACTIONS } from "@/common/constant";

class CustomAbility extends Ability {
  relevantRuleFor(action, subject, field) {
    const rules = this.rulesFor(action, subject, field);

    if (rules.length === 0) {
      return null;
    }

    return super.relevantRuleFor(action, subject, field);
  }

  can(action, subject, override = true) {
    const rule = this.relevantRuleFor(action, subject);

    if (rule === null) {
      return override;
    }

    return super.can(action, subject);
  }
}

export const ability = new CustomAbility();

export function abilityPlugin(store) {
  try {
    const oldStorage = JSON.parse(localStorage.getItem("vuex"));
    let rules = [];
    if (store.state.rules.length > 0) {
      rules = store.state.rules;
    } else if (oldStorage && oldStorage.rules) {
      rules = oldStorage.rules;
    }
    ability.update(rules);

    return store.subscribe((mutation) => {
      switch (mutation.type) {
        case "CREATE_SESSION_RULES":
          if (store?.state?.currentUser?.profile?.Username?.startsWith("IAM@")) {
            const actions = Object.values(ABILITY_ACTIONS);
            return ability.update([
              {
                actions,
                subject: "all",
              },
            ]);
          } else {
            ability.update(mutation.payload);
          }
          break;
        case "destroySession":
          ability.update([{ actions: "read", subject: "all" }]);
          break;
      }
    });
  } catch (err) {
    console.error("err setting abilities: ", err);
  }
}
